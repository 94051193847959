import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listaUsuariosPU = (authtoken, scCompania, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/usuariosPU/listar-usuarios/${scCompania}?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const crearUsuarioPU = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/usuariosPU/crearUsuario`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const editarUsuarioPU = (authtoken, scUsuario, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/usuariosPU/editarUsuario/${scUsuario}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: data,
    })
  );
};

export const eliminarUsuarioPU = (authtoken, scUsuario) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/admin/usuariosPU/eliminarUsuario/${scUsuario}`,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};
