import qs from 'qs';
import axios from 'axios';
import { notification } from 'antd';
import store from '../store/index';
import { LOGIN_CLOSE } from '../store/actions/types';
import jwt from 'jsonwebtoken';

var seguridadUrl = process.env.REACT_APP_SERVICES_URL_LOGIN;
var oauthUrl = process.env.REACT_APP_SERVICES_URL_OAUTH;

var clientId = process.env.REACT_APP_CLIENT_ID;
var scope = process.env.REACT_APP_SCOPE;
var redirectUri = process.env.REACT_APP_REDIRECT_URI;
var codeChallengeMethod = process.env.REACT_APP_CODE_CHALLENGE_METHOD;

var basicAuth = `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`;

const authorize = () => {
  const codeChallenge = sessionStorage.getItem('codeChallenge');
  if (!codeChallenge) {
    window.location.href = `${process.env.PUBLIC_URL}/login`;
  } else {
    const data = {
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      redirect_uri: redirectUri,
      code_challenge: codeChallenge,
      code_challenge_method: codeChallengeMethod,
    };

    const link = `${oauthUrl}/authorize?${qs.stringify(data)}`;

    window.location.href = link;
  }
};

const getToken = (code) => {
  const verifier = sessionStorage.getItem('codeVerifier');
  const codeChallenge = sessionStorage.getItem('codeChallenge');

  const url = `${oauthUrl}/token`;

  const data = new FormData();
  data.append('grant_type', 'authorization_code');
  data.append('client_id', clientId);
  data.append('redirect_uri', redirectUri);
  data.append('code', code);
  data.append('code_verifier', verifier);

  return axios({
    url: url,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: basicAuth,
    },
    data: data,
  }).then((response) => {
    const body = response.data;
    const name = jwt.decode(body.access_token).name;

    const data = {
      ...body,
      name,
    };

    return data;
  });
};

const refreshToken = (refresh_token) => {
  let data = qs.stringify({
    grant_type: 'refresh_token',
    scope: 'openid',
    refresh_token,
  });

  return axios({
    url: `${oauthUrl}/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  })
    .then((response) => {
      const body = response.data;
      const name = jwt.decode(body.access_token).name;

      const data = {
        ...body,
        name,
      };

      return data;
    })
    .catch((error) => {
      localStorage.removeItem('auth-token');
      store.dispatch({ type: LOGIN_CLOSE });
      notification.error({
        message: 'Sesión Finalizada',
        description: 'La sesión ha finalizado, por favor ingrese nuevamente',
        duration: 6,
      });

      window.location.href = `${process.env.PUBLIC_URL}/`;
    });
};

const rememberPassword = (data) => {
  return axios({
    url: `${seguridadUrl}/api/recuperarPassword`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: qs.stringify(data),
  }).then((response) => {
    return response.data;
  });
};

const confirmPassword = (data) => {
  return axios({
    url: `${seguridadUrl}/api/recuperarPassword/confirmar`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  }).then((response) => {
    return response.data;
  });
};

const changePassword = (access_token, data) => {
  return axios({
    url: `${seguridadUrl}/api/cambiarPassword`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${access_token}`,
    },
    data: data,
  }).then((response) => {
    return response.data;
  });
};

const acceptTerms = (authToken) => {
  return axios({
    url: `${seguridadUrl}/api/asociado/actualizarContactoAceptoCondiciones`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
    .then((response) => {
      notification.success({
        message: 'Enviado',
        description: 'Aceptaste los términos y condiciones de la plataforma',
      });
      return { status: true };
    })
    .catch((error) => {
      notification.error({
        message: 'Error',
        description:
          'No fue posible aceptar los términos y condiciones, por favor intentalo de nuevo mas tarde',
      });
      return { status: false };
    });
};

const getAdminUserModules = (authToken, application) => {
  return axios({
    url: `${seguridadUrl}/api/recursos/menu?cdAplicacion=${application}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authToken}`,
    },
    data: null,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    });
};

const revokeToken = (authToken) => {
  store.dispatch({ type: LOGIN_CLOSE });

  window.location.href = `${oauthUrl}/logout`;
};

const grabarLog = (infoError) => {
  let data = qs.stringify({
    log: infoError,
  });

  return axios({
    url: `${seguridadUrl}/api/log/grabar`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${process.env.REACT_APP_SEC_BASIC_AUTH}`,
    },
    data: data,
  })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      console.error('se presento un error inesperado');
    });
};

export default {
  rememberPassword,
  confirmPassword,
  refreshToken,
  changePassword,
  acceptTerms,
  getAdminUserModules,
  revokeToken,
  grabarLog,
  authorize,
  getToken,
};
