import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Switch,
  Spin,
  Space,
} from 'antd';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_POST_FORMAT,
} from '../../../../../utilities/messages';
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';
import { length } from '../../../../../utilities/validate/textValidate';
import equal from 'fast-deep-equal';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const sexo = [
  { key: 'M', description: 'Masculino' },
  { key: 'F', description: 'Femenino' },
];

const estadoCivil = [
  { key: 'C', description: 'Casado' },
  { key: 'SOLT', description: 'Soltero' },
  { key: 'UL', description: 'Unión libre' },
  { key: 'SEP', description: 'Separado' },
  { key: 'V', description: 'Viudo' },
];

const initialValues = {
  cdSnIndependiente: false,
  cdSnInfoEmail: false,
};

const rules = {
  dirResidencia: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisResidencia: [{ required: true, message: requiredValueMessage() }],
  cdDepResidencia: [{ required: true, message: requiredValueMessage() }],
  cdMunResidencia: [{ required: true, message: requiredValueMessage() }],
  telResidencia: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  cdSexo: [{ required: true, message: requiredValueMessage() }],
  cdEstadoCivil: [{ required: true, message: requiredValueMessage() }],
  profesion: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 7, 100),
    },
  ],
  cargo: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 7, 100),
    },
  ],
  dirEmpresa: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 5, 300),
    },
  ],
  cdPaisEmpresa: [{ required: true, message: requiredValueMessage() }],
  cdDepEmpresa: [{ required: true, message: requiredValueMessage() }],
  cdMunEmpresa: [{ required: true, message: requiredValueMessage() }],
  tel1Empresa: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext1Empresa: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  tel2Empresa: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
  ext2Empresa: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 10),
    },
  ],
  email: [
    { required: true, message: requiredValueMessage() },
    {
      pattern:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 200),
    },
  ],
  telCelular: [
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 20),
    },
  ],
};

class GeneralNatural extends React.Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
  }

  handleOnFinish = (formValue) => {
    const { onFinish } = this.props;

    onFinish('3', {
      ...formValue,
      feNacimiento: formValue.feNacimiento
        ? formValue.feNacimiento.format(DATE_POST_FORMAT)
        : undefined,
      cdSnIndependiente: formValue.cdSnIndependiente ? 'S' : 'N',
      cdSnInfoEmail: formValue.cdSnInfoEmail ? 'S' : 'N',
    });
  };

  handleOnChangePaisResidencia = (value) => {
    console.log('handleOnChangePaisResidencia', value);
    const { onChangePaisResidencia } = this.props;
    this.formRef.current.setFieldsValue({
      cdDepResidencia: undefined,
      cdMunResidencia: undefined,
    });
    onChangePaisResidencia(value);
  };

  handleOnChangePaisLaboral = (value) => {
    const { onChangePaisLaboral } = this.props;
    this.formRef.current.setFieldsValue({
      cdDepEmpresa: undefined,
      cdMunEmpresa: undefined,
    });
    onChangePaisLaboral(value);
  };

  handleOnChangeDepartamentoResidencia = (value) => {
    const { onChangeDepartamentoResidencia } = this.props;

    const cdPais = this.formRef.current.getFieldValue('cdPaisResidencia');

    this.formRef.current.setFieldsValue({
      cdMunResidencia: undefined,
    });

    onChangeDepartamentoResidencia(value, cdPais);
  };

  handleOnChangeDepartamentoLaboral = (value) => {
    const { onChangeDepartamentoLaboral } = this.props;

    const cdPais = this.formRef.current.getFieldValue('cdPaisEmpresa');

    this.formRef.current.setFieldsValue({
      cdMunEmpresa: undefined,
    });

    onChangeDepartamentoLaboral(value, cdPais);
  };

  init = () => {
    const { person } = this.props;
    this.formRef.current.setFieldsValue({
      ...initialValues,
      ...person,
      feNacimiento: person.feNacimiento
        ? moment(person.feNacimiento, DATE_FORMAT)
        : '',
      cdSnIndependiente: person.cdSnIndependiente === 'S',
      cdSnInfoEmail: person.cdSnInfoEmail === 'S',
    });
  };

  componentDidMount = () => {
    if (this.props.person) {
      this.init();
    }
  };

  componentDidUpdate(props, state) {
    if (!equal(this.props.person, props.person)) {
      this.init();
    }
  }

  render() {
    const {
      paises,
      departamentosResidencia,
      municipiosResidencia,
      departamentosEmpresa,
      municipiosEmpresa,
    } = this.props;
    return (
      <div className='content-container general-style'>
        <div className='form-container card-background-gray card-shadow card-radius'>
          <Form
            ref={this.formRef}
            name='general_data'
            layout='vertical'
            onFinish={this.handleOnFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='feNacimiento'
                  label='Fecha de nacimiento'
                  rules={rules['feNacimiento'] || ''}
                >
                  <DatePicker
                    className='radius'
                    allowClear={false}
                    inputReadOnly
                    open={false}
                    style={{ width: '100%' }}
                    format={DATE_FORMAT}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='dsPaisNac'
                  label='País de nacimiento'
                  rules={rules['dsPaisNac'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='dsMunNac'
                  label='Ciudad de nacimiento'
                  rules={rules['dsMunNac'] || ''}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdPaisResidencia'
                  label='País de residencia'
                  rules={rules['cdPaisResidencia'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.handleOnChangePaisResidencia}
                  >
                    {paises.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='cdDepResidencia'
                  label='Departamento de residencia'
                  rules={rules['cdDepResidencia'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      departamentosResidencia.fetching ? (
                        <Spin size='small' />
                      ) : null
                    }
                    onChange={this.handleOnChangeDepartamentoResidencia}
                  >
                    {departamentosResidencia.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdMunResidencia'
                  label='Ciudad/Municipio de residencia'
                  rules={rules['cdMunResidencia'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      municipiosResidencia.fetching ? (
                        <Spin size='small' />
                      ) : null
                    }
                  >
                    {municipiosResidencia.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='dirResidencia'
                  label='Dirección de residencia'
                  rules={rules['dirResidencia'] || ''}
                >
                  <Input minLength={5} maxLength={300} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='telResidencia'
                  label='Teléfono'
                  rules={rules['telResidencia'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='cdSexo'
                  label='Sexo'
                  rules={rules['cdSexo'] || ''}
                >
                  <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                    {sexo.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdEstadoCivil'
                  label='Estado civil'
                  rules={rules['cdEstadoCivil'] || ''}
                >
                  <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
                    {estadoCivil.map((item) => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.description}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='profesion'
                  label='Profesión u oficio'
                  rules={rules['profesion'] || ''}
                >
                  <Input minLength={7} maxLength={100} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>Trabajador independiente:</Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnIndependiente'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch checkedChildren='Si' unCheckedChildren='No' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='cargo'
                  label='Cargo que ocupa'
                  rules={rules['cargo'] || ''}
                >
                  <Input minLength={7} maxLength={100} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='cdPaisEmpresa'
                  label='País en el que labora'
                  rules={rules['cdPaisEmpresa'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={this.handleOnChangePaisLaboral}
                  >
                    {paises.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='cdDepEmpresa'
                  label='Departamento en el que  labora'
                  rules={rules['cdDepEmpresa'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      departamentosEmpresa.fetching ? (
                        <Spin size='small' />
                      ) : null
                    }
                    onChange={this.handleOnChangeDepartamentoLaboral}
                  >
                    {departamentosEmpresa.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='cdMunEmpresa'
                  label='Ciudad/Municipio en el que labora'
                  rules={rules['cdMunEmpresa'] || ''}
                >
                  <Select
                    placeholder={SELECT_PLACE_HOLDER}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                      municipiosEmpresa.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {municipiosEmpresa.options.map((item, i) => (
                      <Select.Option key={i} value={item.codigo}>
                        {item.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='dirEmpresa'
                  label='Dirección laboral'
                  rules={rules['dirEmpresa'] || ''}
                >
                  <Input minLength={5} maxLength={300} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='tel1Empresa'
                  label='Teléfono'
                  rules={rules['tel1Empresa'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='ext1Empresa'
                  label='Extensión'
                  rules={rules['ext1Empresa'] || ''}
                >
                  <Input minLength={1} maxLength={10} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='tel2Empresa'
                  label='Teléfono 2'
                  rules={rules['tel2Empresa'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='ext2Empresa'
                  label='Extensión'
                  rules={rules['ext2Empresa'] || ''}
                >
                  <Input minLength={1} maxLength={10} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='email'
                  label='Correo electrónico'
                  rules={rules['email'] || ''}
                >
                  <Input minLength={5} maxLength={200} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='telCelular'
                  label='Número celular'
                  rules={rules['telCelular'] || ''}
                >
                  <Input minLength={7} maxLength={20} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item>
                  <Row justify='start' style={{ paddingTop: '2.5rem' }}>
                    <Col span={12}>
                      <Space>
                        ¿Autoriza recibir información a través del correo
                        electrónico?
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='cdSnInfoEmail'
                        valuePropName='checked'
                        noStyle
                      >
                        <Switch checkedChildren='Si' unCheckedChildren='No' />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row align='middle' justify='center'>
              <Col span={6}>
                <Form.Item>
                  <Button
                    className='form-btn btn-radius'
                    type='primary'
                    htmlType='submit'
                  >
                    Siguiente
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

export default GeneralNatural;
