import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  notification,
  Row,
  Spin,
} from 'antd';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  actualizarSolicitud,
  buscarSolicitudes,
  descargarDocumentos,
} from '../../../../services/admin/requestServices';
import history from '../../../../services/history';
import { setExportZip } from '../../../../utilities/downloadTools';
import { FormRequest } from '../../../proveedor/requests/FormRequest/formRequest';
import FichaClienteView from './fichaClienteView';
import { FormActualizarSolicitud } from './formActualizarSolicitud';
import './requestReports.scss';

export const RequestDetails = () => {
  const [loading, setLoading] = useState(false);
  const [solicitudData, setSolicitudData] = useState({});
  const [showModalActualizar, setShowModalActualizar] = useState(false);
  const [showModalFichaCliente, setshowModalFichaCliente] = useState(false);
  const [scsolicitud, setScsolicitud] = useState('');
  const token = useSelector((state) => state.auth.auth.access_token);

  const params = useParams();

  const handleRegresar = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const search = QueryString.stringify({
      scSolicitud: queryParameters.get('scSolicitud'),
      tipoSolicitud: queryParameters.get('tipoSolicitud'),
      idNit: queryParameters.get('idNit'),
    });
    history.push({
      pathname: `${process.env.PUBLIC_URL}/requests`,
      search,
    });
  };

  const handleDescargarDocs = async () => {
    if (solicitudData.cdEstado === 'PD') {
      notification.warning({
        message: 'Alerta',
        description:
          'No se puede descargar los documentos de una solicitud pendiente de documentación.',
        duration: 4,
      });
      return;
    }
    try {
      setLoading(true);
      const res = await descargarDocumentos(token, scsolicitud);
      setExportZip(res, 'Documentos');
      notification.success({
        message: 'Descargado',
        description: 'Documentos descargados con éxito',
        duration: 4,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error descargando documentos: ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleActualizarSol = () => {
    if (solicitudData.cdEstado === 'PD') {
      notification.warning({
        message: 'Alerta',
        description:
          'No se puede cambiar el estado a una solicitud Pendiente de documentación.',
        duration: 4,
      });
      return;
    } else if (
      solicitudData.cdOrigenSol === 'VD' &&
      solicitudData.cdTipoSolicitud === 'CSR'
    ) {
      notification.warning({
        message: 'Alerta',
        description:
          'En este módulo no se manejan los estados de una vinculación digital - confirming',
        duration: 4,
      });
      return;
    }
    setShowModalActualizar(true);
  };

  const handleMostrarFichaCliente = () => {
    setshowModalFichaCliente(true);
  };

  const handleOnCloseModal = () => {
    setShowModalActualizar(false);
  };

  const handleOnCloseModalFC = () => {
    setshowModalFichaCliente(false);
  };

  const handleOnFinish = async (data) => {
    setShowModalActualizar(false);
    setLoading(true);
    if (data.solExcepcion === undefined || data.solExcepcion === false) {
      data['solExcepcion'] = 'N';
    } else if (data.solExcepcion === true) {
      data['solExcepcion'] = 'S';
    }

    if (data.cdEstado != 'A') {
      delete data.solExcepcion;
    }

    try {
      const res = await actualizarSolicitud(token, scsolicitud, data);
      notification.success({
        message: 'Actualizado',
        description: 'Estado Actualizado con éxito',
        duration: 4,
      });
      getSolicitud(scsolicitud);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error Actualizando solicitud ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const getSolicitud = async (scsolicitud) => {
    let filtro = {
      scSolicitud: scsolicitud,
    };
    setLoading(true);
    try {
      const res = await buscarSolicitudes(token, filtro);
      setSolicitudData(res.data[0]);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error consultando solicitud ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const scSolicitud = Buffer.from(params.scSolicitud, 'base64').toString(
      'utf8'
    );
    setScsolicitud(scSolicitud);
    getSolicitud(scSolicitud);
  }, []);

  return (
    <div className='requestsReports-style'>
      <Spin tip='Cargando...' spinning={loading}>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <h2 align='center'>Solicitud # {scsolicitud}</h2>
          <h3 align='center'>
            Tipo Solicitud: {solicitudData.dsTipoSolicitud}
          </h3>

          <Divider />

          <FormRequest
            tipoSolicitud={solicitudData.cdTipoSolicitud}
            readMode={true}
            recordData={solicitudData}
          />

          <Row align='middle' justify='center' gutter={16}>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-blue'
                onClick={handleRegresar}
              >
                Regresar
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-green'
                onClick={handleDescargarDocs}
              >
                Descargar Documentos
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-blue'
                onClick={handleActualizarSol}
              >
                Actualizar Solicitud
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type='primary'
                className='btn btn-green'
                onClick={handleMostrarFichaCliente}
              >
                Ficha Cliente
              </Button>
            </Col>
          </Row>
          {showModalActualizar && (
            <Modal
              title='Actualizar Solicitud'
              visible={showModalActualizar}
              className='modal-style'
              footer={null}
              onCancel={handleOnCloseModal}
            >
              <FormActualizarSolicitud
                onFinish={handleOnFinish}
                data={
                  solicitudData.cdEstado === 'A' ||
                  solicitudData.cdEstado === 'R' ||
                  solicitudData.cdEstado === 'DI'
                    ? solicitudData
                    : null
                }
                tipoSolicitud={solicitudData.cdTipoSolicitud}
              />
            </Modal>
          )}
          {showModalFichaCliente && (
            <Modal
              visible={showModalFichaCliente}
              footer={null}
              onCancel={handleOnCloseModalFC}
              width={1000}
            >
              <FichaClienteView
                cdOrigen={solicitudData.cdOrigenSol}
                scCompany={
                  solicitudData.cdOrigenSol === 'VD'
                    ? solicitudData.scVinDigital
                    : solicitudData.scProveedor
                }
              />
            </Modal>
          )}
        </Card>
      </Spin>
    </div>
  );
};
