import { Card, Col, Row, Spin } from 'antd';
import QueryString from 'qs';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import loginServices from '../../services/userService';
import { getToken } from '../../store/actions/authActions';
import '../../styles/redirectPage.scss';

const initialState = {
  loading: true,
};

class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const { code } = this.parseQueryString(this.props);
    const { isAuthenticated } = this.props.auth;

    if (!isAuthenticated) {
      if (code) {
        this.props.getToken(code);
      } else {
        loginServices.authorize();
      }
    }
  }

  parseQueryString = (props) => {
    const {
      location: { search },
    } = props;

    const filtro = QueryString.parse(search, { ignoreQueryPrefix: true });

    return {
      ...filtro,
    };
  };

  render() {
    const { loading } = this.state;
    const { isAuthenticated } = this.props.auth;

    if (isAuthenticated) {
      return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
    }

    return (
      <Row className='layout-style' align='middle' justify='center'>
        <Col span={12}>
          <Card className='login-card radius-3'>
            <Row className='row-card' align='middle' justify='center'>
              <Col span={2}>
                <Spin tip='Cargando...' spinning={loading}></Spin>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getToken })(RedirectPage);
