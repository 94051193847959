import { Button, Col, Form, Input, notification, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import People from '../../../assets/img/login-people.png';
import Logo from '../../../assets/img/logo-login.png';
import history from '../../../services/history';
import loginServices from '../../../services/userService';
import {
  compareContraseñaMessage,
  emailPattern,
  formatValueMessage,
  passwordMessage,
  passwordPattern,
  requiredValueMessage,
} from '../../../utilities/admin_validation_messages';
import {
  RESETPASS_CONFIRM_MESSAGE_INIT,
  RESETPASS_MESSAGE_INIT,
  RESETPASS_RECAPTCHA_SITE,
} from '../../../utilities/messages';
import {
  generateCodeChallenge,
  generateCodeVerifier,
} from '../../../utilities/pkce/pkce';
import './login.scss';

const initialChangePasswordFormValue = { password: '', passwordConfirm: '' };

const initialState = {
  username: '',
  password: '',
  email: '',

  externalpass: '',
  loading: false,

  captcha: null,
};

const rules = {
  login: {
    username: [{ required: true, message: requiredValueMessage() }],
    password: [{ required: true, message: requiredValueMessage() }],
  },
  remember: {
    email: [
      { required: true, message: requiredValueMessage() },
      {
        pattern: emailPattern,
        message: formatValueMessage(),
      },
    ],
    captcha: [{ required: true, message: requiredValueMessage() }],
  },
  changePassword: {
    password: [
      { required: true, message: requiredValueMessage() },
      {
        pattern: passwordPattern,
        message: passwordMessage(),
      },
    ],
    passwordConfirm: [
      { required: true, message: requiredValueMessage() },
      {
        pattern: passwordPattern,
        message: passwordMessage(),
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (value !== getFieldValue('password')) {
            return Promise.reject(
              new Error(compareContraseñaMessage('Nueva Contraseña'))
            );
          }

          return Promise.resolve();
        },
      }),
    ],
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.form = React.createRef();
  }

  login = (form) => {
    this.props.login(form.username, form.password);
  };

  rememberPassword = async (form) => {
    this.setState({ ...this.state, loading: true });

    try {
      const response = await loginServices.rememberPassword(form);

      notification.success({
        message: 'Enviado',
        description: `${response.message}`,
        duration: 4,
      });

      this.form.current.resetFields();

      history.push(`${process.env.PUBLIC_URL}/login`);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
        duration: 4,
      });
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  resetPassword = async (form) => {
    const { token } = this.props;

    this.setState({ ...this.state, loading: true });
    try {
      const response = await loginServices.confirmPassword({ ...form, token });

      notification.success({
        message: 'Exitoso',
        description: `${response.message}`,
        duration: 4,
      });

      this.form.current.resetFields();

      history.push(`${process.env.PUBLIC_URL}/login`);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: `${error.response.data.message}`,
        duration: 4,
      });
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  onChangeRecaptcha = (e) => {
    this.setState({
      ...this.state,
      captcha: e,
    });
  };

  renderForm() {
    // eslint-disable-next-line default-case
    switch (this.props.mode) {
      case 'remember': {
        return (
          <Form
            name='remember'
            onFinish={this.rememberPassword}
            ref={this.form}
          >
            <Row>
              <Col span={24}>
                <p className='remember-init-text'>{RESETPASS_MESSAGE_INIT}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item name='email' rules={rules.remember.email}>
                  <Input placeholder='Email' />
                </Form.Item>
              </Col>
            </Row>

            <Row className='recaptchaLogin'>
              <Col span={24}>
                <Form.Item name='captcha' rules={rules.remember.captcha}>
                  <ReCAPTCHA
                    sitekey={RESETPASS_RECAPTCHA_SITE}
                    onChange={this.onChangeRecaptcha}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className='remember-button-container'>
              <Col span={24}>
                <Form.Item name='submit'>
                  <Button className='btn-login btn-radius' htmlType='submit'>
                    Enviar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        );
      }
      case 'confirm': {
        return (
          <Form
            name='confirm'
            onFinish={this.resetPassword}
            initialValues={initialChangePasswordFormValue}
            ref={this.form}
          >
            <Row>
              <Col span={24}>
                <p className='remember-init-text'>
                  {RESETPASS_CONFIRM_MESSAGE_INIT}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name='password'
                  rules={rules.changePassword.password}
                >
                  <Input.Password placeholder='Nueva Contraseña' />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name='passwordConfirm'
                  dependencies={['password']}
                  rules={rules.changePassword.passwordConfirm}
                >
                  <Input.Password placeholder='Confirmar Contraseña' />
                </Form.Item>
              </Col>
            </Row>
            <Row className='login-button-container'>
              <Col span={24}>
                <Form.Item name='submit'>
                  <Button className='btn-login btn-radius' htmlType='submit'>
                    Cambiar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        );
      }
    }
  }

  componentDidMount() {
    const { mode } = this.props;
    if (mode === 'login') {
      this.setState({
        ...this.state,
        loading: true,
      });

      this.loginAccess();
    }
  }

  componentDidUpdate(props, state) {
    const { mode } = this.props;
    if (mode !== props.mode) {
      if (mode === 'login') {
        this.setState({
          ...this.state,
          loading: true,
        });

        this.loginAccess();
      }
    }
  }

  loginAccess = () => {
    const verifier = generateCodeVerifier();
    sessionStorage.setItem('codeVerifier', verifier);
    const codeChallenge = generateCodeChallenge();
    sessionStorage.setItem('codeChallenge', codeChallenge);

    window.location.href = `${process.env.PUBLIC_URL}/redirect`;
  };

  render() {
    const { mode, isAuthenticated } = this.props;
    const { loading } = this.state;

    if (isAuthenticated && mode === 'login') {
      return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />;
    }

    if (mode === 'login') {
      return (
        <div className='login-style-spin'>
          <Row className='row-card' align='middle' justify='center'>
            <Col span={2}>
              <Spin tip='Cargando...' spinning={true}></Spin>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className='login-style'>
          <Spin tip='Cargando...' spinning={loading}>
            <Col className='images'>
              <Row className='logo-container'>
                <Col span={24}>
                  <img src={Logo} alt='' />
                </Col>
              </Row>
              <Row className='people-container'>
                <Col span='24'>
                  <img src={People} alt='' />
                </Col>
              </Row>
            </Col>
            <Col className='login-form-container'>{this.renderForm()}</Col>
          </Spin>
        </div>
      );
    }
  }
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Login);
