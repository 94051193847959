import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

var baseUrl = process.env.REACT_APP_SERVICES_URL_PROVEEDOR;

export const listarEstadoObligacion = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/estado-obligacion`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarCartera = (authtoken, scProveedor, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cartera/listar-cartera?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarHistoricoAbonos = (
  authtoken,
  scProveedor,
  scFactura,
  filtros
) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cartera/listar-historico-abonos/${scFactura}?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const exportarExcelFacturasCartera = (
  authtoken,
  scProveedor,
  filtros
) => {
  filtros = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cartera/reporte-listar-cartera/exportar/xlsx?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarTiposConvenios = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tipo-convenios`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarReintegros = (authtoken, scProveedor, filtros) => {
  let data = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cartera/listar-reintegros?${queryString.stringify(
        data
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

const formatoFechas = (filtros) => {
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') ||
          nombre.includes('Hasta') ||
          nombre.includes('feLiquidacion') ||
          nombre.includes('feRecaudo')) &&
        typeof filtros[nombre] === 'object'
      ) {
        filtros[nombre] = filtros[nombre].format('YYYYMMDD');
      }
    }
  });
  return filtros;
};
