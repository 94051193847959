import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, notification, Row, Spin } from 'antd';
import QueryString from 'qs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TableReport from '../../../../components/tableReport/tableReport';
import {
  buscarSolicitudes,
  descargarReporte,
} from '../../../../services/admin/requestServices';
import { setExcel } from '../../../../utilities/downloadTools';
import { sorterText } from '../../../../utilities/textTools';
import { FormFilterReport } from './formFilterReport/formFilterReport';
import './requestReports.scss';

export const RequestReports = (props) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.auth.access_token);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState('+feRadicado');
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [resultado, setResultado] = useState([]);
  const [filterFormData, setFilterFormData] = useState(null);

  const handleSearchFilterForm = (formFilterData) => {
    setFilterFormData(formFilterData);
  };

  const getFiltro = () => {
    let filtro = {
      limit: limit,
      offset: offset,
      sort: sort,
      solRadicadas: true,
    };

    if (filterFormData && filterFormData.scSolicitud) {
      filtro['scSolicitud'] = filterFormData.scSolicitud;
    }

    if (filterFormData && filterFormData.tipoSolicitud) {
      filtro['tipoSolicitud'] = filterFormData.tipoSolicitud;
    }

    if (filterFormData && filterFormData.idNit) {
      filtro['idNit'] = filterFormData.idNit;
    }

    return filtro;
  };

  const getSolicitudes = async () => {
    setLoading(true);
    try {
      const res = await buscarSolicitudes(token, getFiltro());
      setResultado(res.data);
      setTotal(res.metadata.count);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Error listando solicitudes ' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const exportExcel = async () => {
    setLoading(true);
    try {
      const response = await descargarReporte(token, getFiltro());
      setExcel(response, 'Solicitudes');
    } catch (error) {
      notification.error({
        message: 'Error al descargar reporte',
        description: '' + error,
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setLimit(e.limit);
    if (e.sort) {
      setSort(e.sort);
    } else {
      setSort('+feRadicado');
    }
    setOffset(e.offset);
    setCurrentPage(e.currentPage);
  };

  useEffect(() => {
    props.menuHandler('46');
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.size > 0) {
      let urldata = {
        scSolicitud: queryParameters.get('scSolicitud'),
        tipoSolicitud: queryParameters.get('tipoSolicitud'),
        idNit: queryParameters.get('idNit'),
      };
      setFilterFormData(urldata);
    } else {
      getSolicitudes();
    }
  }, []);

  useEffect(() => {
    getSolicitudes();
  }, [limit, offset, sort, currentPage]);

  useEffect(() => {
    if (filterFormData !== null) {
      getSolicitudes();
    }
  }, [filterFormData]);

  const columnas = [
    {
      title: 'FECHA CREACIÓN',
      dataIndex: 'feRadicado',
      sorter: (a, b) => sorterText(a.feRadicado, b.feRadicado),
      showSorterTooltip: false,
    },
    {
      title: 'CONSECUTIVO',
      render: (record) => (
        <Link
          to={`${process.env.PUBLIC_URL}/request/${Buffer.from(
            record.scSolicitud
          ).toString('base64')}${
            filterFormData
              ? '?' +
                QueryString.stringify({
                  scSolicitud: filterFormData.scSolicitud,
                  tipoSolicitud: filterFormData.tipoSolicitud,
                  idNit: filterFormData.idNit,
                })
              : ''
          }`}
        >
          <p>{record.scSolicitud}</p>
        </Link>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'NIT/ID',
      dataIndex: 'idNit',
      sorter: (a, b) => sorterText(a.idNit, b.idNit),
      showSorterTooltip: false,
    },
    {
      title: 'NOMBRE/RAZÓN SOCIAL',
      dataIndex: 'nombreRazonSocial',
      sorter: (a, b) => sorterText(a.nombreRazonSocial, b.nombreRazonSocial),
      showSorterTooltip: false,
    },
    {
      title: 'TIPO SOLICITUD',
      dataIndex: 'dsTipoSolicitud',
      sorter: (a, b) => sorterText(a.dsTipoSolicitud, b.dsTipoSolicitud),
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'dsEstado',
      sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
      showSorterTooltip: false,
    },
  ];

  return (
    <div className='requestsReports-style'>
      <Spin tip='Cargando...' spinning={loading}>
        <Card className='card-shadow card-radius default-border welcome-message'>
          <h2 align='center'>Solicitudes Producto</h2>
          <FormFilterReport handleSubmit={handleSearchFilterForm} />
          <Divider />
          <div className='table-container'>
            {resultado.length > 0 && !loading && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      style={{ marginRight: '2rem', marginBottom: '1.5rem' }}
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            {resultado.length > 0 ? (
              <Row gutter={16}>
                <Col span={24}>
                  <TableReport
                    currentPage={currentPage}
                    limit={limit}
                    total={total}
                    columns={columnas}
                    data={resultado}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </Card>
      </Spin>
    </div>
  );
};
