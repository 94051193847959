import axios from 'axios';
import { notification } from 'antd';
import queryString from 'query-string';
import { middleWare } from '../middleware';

var baseUrl = process.env.REACT_APP_SERVICES_URL_PROVEEDOR;

const listarGruposEmpresariales = (authToken, scProveedor, scFondeador) => {
  return axios({
    url: `${baseUrl}/api/companias/listarGruposEmpCompania/${scProveedor}/${scFondeador}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    if (response.error) {
    } else {
      return response.data;
    }
  });
};

const listarPreofertas = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/ofertas/listarPreOfertaPorProveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const listarNotas = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/facturas/listarNotasCredito/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const listarOfertaCompra = (authToken, scProveedor, scFondeador, scGrupo) => {
  var grupoString = '';
  if (scGrupo) {
    grupoString = `?scGrupo=${scGrupo}`;
  }
  return axios({
    url: `${baseUrl}/api/ofertas/listarOfertaCompraPorProveedor/${scProveedor}/${scFondeador}${grupoString}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
  // .catch((error) => {
  //     if (error.response.status === 401) {
  //         localStorage.removeItem('auth-token');
  //         window.location.replace("http://localhost:3000/login");
  //     }
  // })
};

export const listarCuentasProveedor = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/cuenta/listarCuentasProveedor/${scProveedor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const solicitarAffitoken = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/affitoken/solicitarAffitoken/${scProveedor}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const validarAffitoken = (authToken, objAffitoken) => {
  return axios({
    url: `${baseUrl}/api/affitoken/validarAffitoken`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {
      objAffitoken,
    },
  }).then((response) => {
    return response.data;
  });
};

const confirmarCompraOferta = (authToken, scProveedor, bills) => {
  return axios({
    url: `${baseUrl}/api/carrito-compra/${scProveedor}/guardar`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: bills,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.data.messages) {
        error.response.data.messages.forEach((er) => {
          notification.error({
            message: 'Error',
            description: er.message,
            duration: 5,
          });
        });
      } else {
        notification.error({
          message: 'Error',
          description: error.response.data.message,
          duration: 5,
        });
      }
      return {
        data: 'error',
      };
    });
};

const resumenCompraOferta = (authToken, scProveedor, tipo) => {
  return axios({
    url: `${baseUrl}/api/carrito-compra/${scProveedor}/resumen/${tipo}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {},
  }).then((response) => {
    return response.data;
  });
};

const confirmarCarrito = (
  authToken,
  scProveedor,
  affiToken,
  scCuenta,
  tipo
) => {
  var array = {};
  array.affitoken = affiToken;
  if (scCuenta !== '') {
    array.scCuenta = scCuenta;
  }

  return new Promise((resolve, reject) => {
    axios({
      url: `${baseUrl}/api/carrito-compra/${scProveedor}/confirmar/${tipo}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: array,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: 'Ha ocurrido un error: ' + error.response.data.message,
          duration: 6,
        });

        reject({
          status: 500,
          message: 'Ha ocurrido un error: ' + error.response.data.message,
        });
      });
  });
};

const listarOfertasCobrarTodo = (authToken, scProveedor, valor) => {
  if (valor > 0) {
    valor = `?valor=${valor}`;
  } else {
    valor = '';
  }
  return axios({
    url: `${baseUrl}/api/ofertas/negociarTodo/${scProveedor}${valor}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const verFacturasResumen = (
  authToken,
  scProveedor,
  scFondeador,
  scGrupo,
  vrOfertaTotal
) => {
  return axios({
    url: `${baseUrl}/api/ofertas/verFacturasResumen/${scProveedor}?scFondeador=${scFondeador}&scGrupo=${scGrupo}&vrOfertaTotal=${vrOfertaTotal}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};
const verNotasResumen = (
  authToken,
  scProveedor,
  scFondeador,
  scGrupo,
  vrOfertaTotal
) => {
  return axios({
    url: `${baseUrl}/api/ofertas/verNotasResumen/${scProveedor}?scFondeador=${scFondeador}&scGrupo=${scGrupo}&vrOfertaTotal=${vrOfertaTotal}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const preofertasExcel = (authToken, scProveedor, scFondeador, scGrupo) => {
  var filter = '';
  if (scGrupo !== 'all') {
    filter = `?scGrupo=${scGrupo}`;
  }
  return axios({
    url: `${baseUrl}/api/ofertas/listarOfertaCompraPorProveedor/exportar/xlsx/${scProveedor}/${scFondeador}${filter}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const programacionPreofertasExcel = (
  authToken,
  scProveedor,
  scFondeador,
  filtros
) => {
  if (filtros.scGrupo === 'all') {
    delete filtros.scGrupo;
  }
  return axios({
    url: `${baseUrl}/api/ofertas/${scProveedor}/programacion/${scFondeador}/listarOfertaCompra/exportar/xlsx?${queryString.stringify(
      filtros
    )}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: null,
  }).then((response) => {
    return response.data;
  });
};

const resumenCompraOfertaProgramacion = (
  authToken,
  scProveedor,
  fechaOferta
) => {
  return axios({
    url: `${baseUrl}/api/ofertas/${scProveedor}/programacion/listarPreOfertaPorProveedor?fechaOferta=${fechaOferta}`,

    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {},
  }).then((response) => {
    return response.data;
  });
};
const fechasActivasProgramacion = (authToken, scProveedor) => {
  return axios({
    url: `${baseUrl}/api/ofertas/${scProveedor}/programacion/listarDiasHabiles`,

    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {},
  }).then((response) => {
    return response.data;
  });
};

const listarPreOfertaProgramacion = (
  authToken,
  scProveedor,
  scFondeador,
  fechaOferta,
  scGrupo
) => {
  var filter = '';
  if (scGrupo !== 'all') {
    filter = `&scGrupo=${scGrupo}`;
  }
  return axios({
    url: `${baseUrl}/api/ofertas/${scProveedor}/programacion/${scFondeador}/listarOfertaCompra?fechaOferta=${fechaOferta}${filter}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    data: {},
  }).then((response) => {
    return response.data;
  });
};

const addCufe = (authtoken, scProveedor, formValue) => {
  const formData = new FormData();
  formData.append('scFactura', formValue.scFactura);
  formData.append('tipoRegistro', formValue.tipoCarga);

  if (formValue.tipoCarga === 'XML') {
    if (formValue.file && formValue.file.length > 0) {
      formData.append('file', formValue.file[0].originFileObj);
    }
  } else {
    formData.append('cufe', formValue.cufe);
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cufe/grabar`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

const importCufeMasivo = (authtoken, scProveedor, file) => {
  const formData = new FormData();
  formData.append('file', file[0].originFileObj);

  return middleWare(
    axios({
      url: `${baseUrl}/api/${scProveedor}/cufe/cargar-archivo`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export default {
  listarGruposEmpresariales,
  listarNotas,
  listarOfertaCompra,
  listarPreofertas,
  listarCuentasProveedor,
  solicitarAffitoken,
  validarAffitoken,
  confirmarCompraOferta,
  resumenCompraOferta,
  confirmarCarrito,
  listarOfertasCobrarTodo,
  verFacturasResumen,
  verNotasResumen,
  preofertasExcel,
  programacionPreofertasExcel,
  resumenCompraOfertaProgramacion,
  listarPreOfertaProgramacion,
  fechasActivasProgramacion,
  addCufe,
  importCufeMasivo,
};
