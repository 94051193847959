import axios from 'axios';
import { middleWare } from '../middleware';
import moment from 'moment';
import { notification } from 'antd';
var baseUrl = process.env.REACT_APP_SERVICES_URL_PROVEEDOR;

/**
 *
 * @param {*} authToken
 * @param {*} scProveedor
 */
export const resumenFacturasProveedor = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/${scProveedor}/resumen-facturas`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const soporteComisiones = (authToken, scProveedor, activeFilters) => {
  var filters = '';
  var concat = '?';
  if (activeFilters.feDesde && activeFilters.feHasta) {
    let { feDesde, feHasta } = activeFilters;
    if (validateDates(feDesde, feHasta)) {
      return new Promise((resolve, reject) => {
        resolve({
          status: false,
          message: 'Error en el servicio',
        });
      });
    }
  }

  const regex = /[^0-9]/g;
  if (activeFilters.feDesde) {
    var feDesde = activeFilters.feDesde.replace(regex, '');
    filters += `${concat}fechaDesdeEmision=${feDesde}`;
  }
  if (activeFilters.feHasta) {
    if (filters !== '') {
      concat = '&';
    }
    var feHasta = activeFilters.feHasta.replace(regex, '');
    filters += `${concat}fechaHastaEmision=${feHasta}`;
  }
  if (activeFilters.limit) {
    if (filters !== '') {
      concat = '&';
    }
    filters += `${concat}limit=${activeFilters.limit}`;
  }
  if (activeFilters.offset) {
    if (filters !== '') {
      concat = '&';
    }
    filters += `${concat}offset=${activeFilters.offset}`;
  }
  if (activeFilters.sort) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}sort=${activeFilters.sort}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/factura-exp/${scProveedor}/pagos-comisiones${filters}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const soporteComisionesExcel = (
  authToken,
  scProveedor,
  activeFilters
) => {
  var filters = '';
  var concat = '?';
  const regex = /[^0-9]/g;
  if (activeFilters.feDesde) {
    var feDesde = activeFilters.feDesde.replace(regex, '');
    filters += `${concat}fechaDesdeEmision=${feDesde}`;
  }
  if (activeFilters.feHasta) {
    if (filters !== '') {
      concat = '&';
    }
    var feHasta = activeFilters.feHasta.replace(regex, '');
    filters += `${concat}fechaHastaEmision=${feHasta}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/factura-exp/${scProveedor}/pagos-comisiones/exportar/xlsx${filters}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const soporteComisionesDetallePDF = (
  authToken,
  scProveedor,
  nmFactura
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/factura-exp/${scProveedor}/pagos-comisiones/descargarComision?nmFacturas=${nmFactura}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};
export const soporteComisionesDetalleAnexoPDF = (
  authToken,
  scProveedor,
  nmFactura
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/factura-exp/${scProveedor}/pagos-comisiones/descargarFactura?nmFacturas=${nmFactura}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const soporteTransaccionesFinalizadas = (
  authToken,
  scProveedor,
  activeFilters
) => {
  var filters = '';
  var concat = '?';
  const regex = /[^0-9]/g;

  if (activeFilters.feDesde && activeFilters.feHasta) {
    let { feDesde, feHasta } = activeFilters;
    if (validateDates(feDesde, feHasta)) {
      return new Promise((resolve, reject) => {
        resolve({
          status: false,
          message: 'Error en el servicio',
        });
      });
    }
  }

  if (activeFilters.feDesde) {
    var feDesde = activeFilters.feDesde.replace(regex, '');
    filters += `${concat}fechaDesde=${feDesde}`;
  }
  if (activeFilters.feHasta) {
    if (filters !== '') {
      concat = '&';
    }
    var feHasta = activeFilters.feHasta.replace(regex, '');
    filters += `${concat}fechaHasta=${feHasta}`;
  }
  if (activeFilters.limit) {
    if (filters !== '') {
      concat = '&';
    }
    filters += `${concat}limit=${activeFilters.limit}`;
  }
  if (activeFilters.offset) {
    if (filters !== '') {
      concat = '&';
    }
    filters += `${concat}offset=${activeFilters.offset}`;
  }
  if (activeFilters.transactionId) {
    if (filters !== '') {
      concat = '&';
    }
    filters += `${concat}scTracking=${activeFilters.transactionId}`;
  }
  if (activeFilters.sort) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}sort=${activeFilters.sort}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/reporteSoporteTransaccionFinalizada/${scProveedor}${filters}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const reporteFacturasFinalizadas = (
  authToken,
  scProveedor,
  feDesde,
  feHasta,
  activeFilters
) => {
  var filters = '';
  if (activeFilters.billNumber) {
    filters += `&cdFactura=${activeFilters.billNumber}`;
  }
  if (activeFilters.limit) {
    filters += `&limit=${activeFilters.limit}`;
  }
  if (activeFilters.group) {
    filters += `&scGrupo=${activeFilters.group}`;
  }
  if (activeFilters.payer) {
    filters += `&scPagador=${activeFilters.payer}`;
  }
  if (activeFilters.account) {
    filters += `&scCuenta=${activeFilters.account}`;
  }
  if (activeFilters.investor) {
    filters += `&scFondeador=${activeFilters.investor}`;
  }
  if (activeFilters.offset) {
    filters += `&offset=${activeFilters.offset}`;
  }
  if (activeFilters.sort) {
    filters += `&sort=${activeFilters.sort}`;
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/listarRepFacturasFinalizadas/${scProveedor}?feDesde=${feDesde}&feHasta=${feHasta}${filters}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const reporteFacturasFinalizadasExcel = (
  authToken,
  scProveedor,
  feDesde,
  feHasta,
  activeFilters
) => {
  var filters = '';
  if (activeFilters.billNumber) {
    filters += `&cdFactura=${activeFilters.billNumber}`;
  }
  if (activeFilters.limit) {
    filters += `&limit=${activeFilters.limit}`;
  }
  if (activeFilters.group) {
    filters += `&scGrupo=${activeFilters.group}`;
  }
  if (activeFilters.payer) {
    filters += `&scPagador=${activeFilters.payer}`;
  }
  if (activeFilters.account) {
    filters += `&scCuenta=${activeFilters.account}`;
  }
  if (activeFilters.investor) {
    filters += `&scFondeador=${activeFilters.investor}`;
  }
  if (activeFilters.offset) {
    filters += `&offset=${activeFilters.offset}`;
  }
  if (activeFilters.sort) {
    filters += `&sort=${activeFilters.sort}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/excelListarRepFacturasFinalizadas/${scProveedor}?feDesde=${feDesde}&feHasta=${feHasta}${filters}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const reporteFacturasEnTramiteExcel = (
  authToken,
  scProveedor,
  activeFilters
) => {
  var filters = '';
  var concat = '?';
  if (activeFilters.group) {
    filters += `?scGrupo=${activeFilters.group}`;
  }
  if (activeFilters.payer) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}scPagador=${activeFilters.payer}`;
  }
  if (activeFilters.investor) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}scFondeador=${activeFilters.investor}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/excelListarRepFacturasTramite/${scProveedor}${filters}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const reporteSoporteTransaccionesFinalizadasExcel = (
  authToken,
  scProveedor,
  activeFilters
) => {
  var filters = '';
  var concat = '?';
  const regex = /[^0-9]/g;
  if (activeFilters.feDesde) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    var feDesde = activeFilters.feDesde.replace(regex, '');
    filters += `${concat}feDesde=${feDesde}`;
  }
  if (activeFilters.feHasta) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    var feHasta = activeFilters.feHasta.replace(regex, '');
    filters += `${concat}feHasta=${feHasta}`;
  }
  if (activeFilters.transactionId) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}scTracking=${activeFilters.transactionId}`;
  }

  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/excelListaResumenTracking/${scProveedor}${filters}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const reporteFacturasTramite = (
  authToken,
  scProveedor,
  activeFilters
) => {
  var filters = '';
  var concat = '?';
  if (activeFilters.group) {
    filters += `?scGrupo=${activeFilters.group}`;
  }
  if (activeFilters.payer) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}scPagador=${activeFilters.payer}`;
  }
  if (activeFilters.investor) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}scFondeador=${activeFilters.investor}`;
  }
  if (activeFilters.offset) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}offset=${activeFilters.offset}`;
  }
  if (activeFilters.limit) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}limit=${activeFilters.limit}`;
  }
  if (activeFilters.sort) {
    if (filters !== '') {
      concat = '&';
    } else {
      concat = '?';
    }
    filters += `${concat}sort=${activeFilters.sort}`;
  }
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/listarRepFacturasTramite/${scProveedor}${filters}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const flujoDeCajaEsperado = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/buscarFlujoCaja/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const facturasProveedorPorCompania = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/reportes/facturasProveedorPorCompania/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarGrupos = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/listarGruposEmpProveedor/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarPagadores = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/listarPagadoresProveedor/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarFondeadores = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/companias/listarFondeadoresProveedor/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const enviarComprobante = (
  authToken,
  scProveedor,
  scTracking,
  email
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/enviar-mail/${scTracking}?email=${email}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const descargarComprobante = (authToken, scProveedor, scTracking) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/descargar/${scTracking}`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarTracking = (authToken, scProveedor) => {
  const toDate = moment().format('YYYYMMDD');
  const fromDate = moment().subtract(30, 'days').format('YYYYMMDD');
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}?fechaDesde=${fromDate}&fechaHasta=${toDate}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};
export const listarTrackingUnico = (authToken, scProveedor, tracking) => {
  const toDate = moment().format('YYYYMMDD');
  const fromDate = moment().subtract(30, 'days').format('YYYYMMDD');
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}?fechaDesde=${fromDate}&fechaHasta=${toDate}&scTracking=${tracking}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const cancelTracking = (authToken, scProveedor, scTracking) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/anular/${scTracking}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const cancelTrackingByBill = (
  authToken,
  scProveedor,
  scTracking,
  scFactura
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/anular/${scTracking}/${scFactura}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarTrackingDetalleFacturas = (
  authToken,
  scProveedor,
  scTracking
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/detalle/${scTracking}/facturas`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

export const listarTrackingDetalleNotasCredito = (
  authToken,
  scProveedor,
  scTracking
) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tracking/${scProveedor}/detalle/${scTracking}/notas-credito`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: null,
    })
  );
};

const validateDates = (from, to) => {
  let momentFrom = moment(from, 'YYYYMMDD');
  let momentTo = moment(to, 'YYYYMMDD');
  let diff = momentFrom.diff(momentTo);

  if (diff > 0) {
    notification.error({
      message: 'Error',
      description: 'La fecha inicial debe ser menor a la fecha final',
      duration: 5,
    });
    return true;
  } else {
    return false;
  }
};
