import { Button, Card, Col, Divider, notification, Row, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TableReport from '../../../components/tableReport/tableReport';
import {
  guardar,
  listar,
} from '../../../services/fondeador/disbursementServices';
import {
  listarConvenios,
  listarPagadores,
  listarProveedores,
} from '../../../services/fondeador/reportServices';
import { currencyFormat, sorterText } from '../../../utilities/textTools';
import './disbursement.scss';
import DisbursementFilterForm from './disbursementFilterForm/disbursementFilterForm';

const initialState = {
  form: {
    cdFactura: '',
    scConvenio: '',
    scPagador: '',
    scProveedor: '',
    fechaDesdeCompra: '',
    fechaHastaCompra: '',
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  resultado: [],
  loading: false,
  selectedKeys: [],
  selectAll: false,
  unselectedKeys: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'nombrePagador',
      colSpan: 4,
    },
    {
      id: 'sumaVrFactura',
      value: currencyFormat(0),
      index: 'vrFactura',
      colSpan: 1,
    },
    {
      id: 'sumaVrDesembolso',
      value: currencyFormat(0),
      index: 'vrDesembolso',
      colSpan: 1,
    },
  ],
};

class Disbursement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columnas = [
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'PROOVEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR FACTURA',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR DESEMBOLSO',
        dataIndex: 'vrDesembolso',
        sorter: (a, b) => a.vrDesembolso - b.vrDesembolso,
        render: (value) => <span>{currencyFormat(value)}</span>,
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });
      try {
        const response = await listarConvenios(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
      },
      sort: '',
      currentPage: initialState.currentPage,
      offset: initialState.offset,
      selectedKeys: [],
      selectAll: false,
      unselectedKeys: [],
    });

    this.searchData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listar(
          this.props.authToken,
          this.props.company.company,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {currencyFormat(response.metadata.sumatorias[res.id])}
              </span>
            );
          }

          return res;
        });

        response.data.forEach((element) => {
          element.key = element.scFactura;
          if (this.state.selectAll) {
            let unselected = this.state.unselectedKeys.find(
              (key) => key === element.key
            );
            if (!unselected) {
              if (!this.state.selectedKeys.find((key) => element.key === key)) {
                this.state.selectedKeys = [
                  ...this.state.selectedKeys,
                  element.key,
                ];
              }
            }
          }
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
        });
      } finally {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  onSelect = (record, selected) => {
    const { selectAll } = this.state;
    let unselectedKeys = [];
    if (selected) {
      if (this.state.selectAll) {
        unselectedKeys = this.state.unselectedKeys.filter(
          (key) => key !== record.key
        );
      }
      this.setState({
        ...this.state,
        selectedKeys: [...this.state.selectedKeys, record.key],
        unselectedKeys: unselectedKeys,
      });
    } else {
      if (selectAll) {
        unselectedKeys = [...this.state.unselectedKeys, record.key];
      }
      let filterKeys = this.state.selectedKeys.filter(
        (selectedKey) => selectedKey !== record.key
      );
      this.setState({
        ...this.state,
        selectedKeys: filterKeys,
        unselectedKeys: unselectedKeys,
      });
    }
  };

  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      changeRows.forEach((row) => {
        let selected = this.state.selectedKeys.find((key) => row.key === key);
        if (!selected) {
          this.state.selectedKeys = [...this.state.selectedKeys, row.key];
        }
      });
      this.setState({
        ...this.state,
        unselectedKeys: [],
        selectAll: selected,
      });
    } else {
      this.setState({
        ...this.state,
        selectedKeys: [],
        unselectedKeys: [],
        selectAll: selected,
      });
    }
  };

  confirmar = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      let keys = [];
      if (this.state.selectAll) {
        keys = this.state.unselectedKeys;
      } else {
        keys = this.state.selectedKeys;
      }

      const response = await guardar(
        this.props.authToken,
        this.props.company.company,
        keys,
        this.state.selectAll,
        this.getFiltro()
      );

      notification.success({
        message: 'Operación finalizada',
        description: response ? response.message : 'Error inesperado',
        duration: 5,
      });

      this.setState({
        ...this.state,
        currentPage: 1,
        offset: 0,
        total: 0,
        resultado: [],
        selectedKeys: [],
        selectAll: false,
        unselectedKeys: [],
      });

      this.searchData();
    } catch (error) {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.props.menuHandler('3');
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.setState({
        ...this.initialState,
        loading: false,
        resumen: [
          {
            id: 'total',
            value: 'Total:',
            index: 'nombrePagador',
            colSpan: 4,
          },
          {
            id: 'sumaVrFactura',
            value: currencyFormat(0),
            index: 'vrFactura',
            colSpan: 1,
          },
          {
            id: 'sumaVrDesembolso',
            value: currencyFormat(0),
            index: 'vrDesembolso',
            colSpan: 1,
          },
        ],
      });
    }
  }
  render() {
    const {
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      loading,

      currentPage,
      limit,
      total,
      resumen,
      resultado,
      selectedKeys,
    } = this.state;

    this.rowSelection = {
      type: 'checkbox',
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelect,
      selectedRowKeys: selectedKeys,
    };

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='disbursement-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <DisbursementFilterForm
              companyId={this.props.company.company}
              proveedorSelect={proveedorSelect}
              pagadorSelect={pagadorSelect}
              convenioSelect={convenioSelect}
              handleOnSearchConvenio={this.handleOnSearchConvenio}
              handleOnSearchProveedor={this.handleOnSearchProveedor}
              handleOnSearchPagador={this.handleOnSearchPagador}
              handleSubmit={this.handleSubmit}
            />
            <div className='table-container'>
              <Row gutter={30} className='bill-selection'>
                <Col span={12}>
                  <p className='title'>
                    Lista de facturas pendientes por desembolso
                  </p>
                </Col>
              </Row>
              <Divider />
              <TableReport
                keyIndex={'scFactura'}
                rowSelection={this.rowSelection}
                currentPage={currentPage}
                limit={limit}
                total={total}
                resumen={resumen}
                columns={this.columnas}
                data={resultado}
                handleChange={this.handleChange}
              />
              <Row className='notify-container'>
                <Col span={24}>
                  <Button
                    className='form-btn btn-notify'
                    type='primary'
                    htmlType='submit'
                    onClick={this.confirmar}
                  >
                    Confirmar desembolso
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Disbursement);
