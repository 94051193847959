import React, { Component } from 'react';
import { Row, Col, Button, Divider, Spin, notification } from 'antd';
import '../operation.scss';

import { SettingOutlined } from '@ant-design/icons';
import { listarConvenios } from '../../../../services/fondeador/operationServices';
import AgreementsFilterFrom from './agreementsFilterFrom/agreementsFilterFrom';
import * as reportServices from '../../../../services/fondeador/reportServices';
import TableReport from '../../../../components/tableReport/tableReport';
import { sorterText } from '../../../../utilities/textTools';
import AgreementsDetail from './agreementsDetail/agreementsDetail';
import { COMPANY_SELECT_MESSAGE } from '../../../../utilities/messages';

const initialState = {
  form: {
    nombreConvenio: '',
    scPagador: '',
    scProveedor: '',
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  scConvenio: '',
  loading: false,
  resultado: [],
  sort: 'nombrePagador',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
};

class Agreements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await reportServices.listarPagadores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await reportServices.listarProveedores(
          this.props.authToken,
          this.props.company.company,
          {
            nombre: nombre,
          }
        );
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        nombreConvenio: value.nombreConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  searchData = async () => {
    if (this.props.company.company === '0') {
      notification.warn({
        message: 'Convenios',
        description: COMPANY_SELECT_MESSAGE,
      });
      return;
    }

    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listarConvenios(
        this.props.authToken,
        this.props.company.company,
        this.getFiltro()
      );

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
      });
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  getSortOrder = (sort, column) => {
    let order = '+';
    let col = sort;
    if (sort.includes('+') || sort.includes('-')) {
      col = sort.substr(1, sort.length);
      order = sort.charAt(0);
    }

    if (col === column) {
      return order === '-' ? 'descend' : 'ascend';
    }

    return false;
  };

  editarConvenio = (record) => {
    this.setState({
      ...this.state,
      scConvenio: record.scConvenio,
    });
  };

  componentDidMount() {
    if (typeof this.props.company.company !== 'object') {
      this.searchData();
    }
  }

  componentDidUpdate(props, state) {
    if (this.props.company.company !== props.company.company) {
      this.state.resultado = [];
      this.state.total = 0;
      this.state.currentPage = 1;
      this.state.offset = 0;
      this.state.scConvenio = '';

      this.searchData();
    }
  }

  render() {
    const {
      pagadorSelect,
      proveedorSelect,
      loading,
      currentPage,
      limit,
      total,
      resultado,
      sort,
      scConvenio,
    } = this.state;

    this.columnas = [
      {
        title: 'TIPO IDENTIFICACIÓN',
        dataIndex: 'dsPagadorTipoId',
        key: 'dsPagadorTipoId',
        sorter: (a, b) => sorterText(a.dsPagadorTipoId, b.dsPagadorTipoId),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'dsPagadorTipoId'),
      },
      {
        title: 'NRO IDENTIFICACIÓN',
        dataIndex: 'pagadorId',
        key: 'pagadorId',
        sorter: (a, b) => sorterText(a.pagadorId, b.pagadorId),
        sortOrder: this.getSortOrder(sort, 'pagadorId'),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        key: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'nombrePagador'),
      },
      {
        title: 'NOMBRE CONVENIO',
        dataIndex: 'nombre',
        key: 'nombre',
        sorter: (a, b) => sorterText(a.nombre, b.nombre),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'nombre'),
      },
      {
        title: 'TIPO CONVENIO',
        dataIndex: 'dsTipoConvenio',
        key: 'dsTipoConvenio',
        sorter: (a, b) => sorterText(a.dsTipoConvenio, b.dsTipoConvenio),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'dsTipoConvenio'),
      },
      {
        title: 'ESTADO',
        dataIndex: 'dsEstado',
        key: 'dsEstado',
        sorter: (a, b) => sorterText(a.dsEstado, b.dsEstado),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'dsEstado'),
      },
      {
        title: 'DESCRIPCIÓN CONVENIO',
        dataIndex: 'dsConvenio',
        key: 'dsConvenio',
        sorter: (a, b) => sorterText(a.dsConvenio, b.dsConvenio),
        showSorterTooltip: false,
        sortOrder: this.getSortOrder(sort, 'dsConvenio'),
      },
      {
        title: 'EDITAR',
        dataIndex: '',
        render: (text, record) => (
          <Button
            className='btn-edit'
            icon={<SettingOutlined />}
            onClick={() => this.editarConvenio(record)}
          />
        ),
        showSorterTooltip: false,
      },
    ];

    return (
      <div className='reports-container'>
        <AgreementsFilterFrom
          companyId={this.props.company.company}
          proveedorSelect={proveedorSelect}
          pagadorSelect={pagadorSelect}
          handleOnSearchProveedor={this.handleOnSearchProveedor}
          handleOnSearchPagador={this.handleOnSearchPagador}
          handleSubmit={this.handleSubmit}
        />
        <Row gutter={30} className='header-search-container'>
          <Col span={12}>
            <p className='title'>Lista de convenios</p>
          </Col>
        </Row>
        <Divider />
        <div className='table-container'>
          <Spin tip='Cargando...' spinning={loading}>
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </Spin>
        </div>
        {scConvenio !== '' && (
          <AgreementsDetail
            scConvenio={scConvenio}
            company={this.props.company}
            authToken={this.props.authToken}
          />
        )}
      </div>
    );
  }
}

export default Agreements;
