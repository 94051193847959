import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { consultaTiposActividades } from '../../../services/vinculacion/suppliersService';
import {
  emailPattern,
  formatValueMessage,
  maxValueMessage,
  requiredValueMessage,
} from '../../../utilities/admin_validation_messages';
import {
  DATE_FORMAT,
  RESETPASS_RECAPTCHA_SITE,
} from '../../../utilities/messages';
import {
  currencyInputFormat,
  currencyInputParser,
} from '../../../utilities/textTools';
import * as numberValidate from '../../../utilities/validate/numberValidate';
import { length, totalLength } from '../../../utilities/validate/textValidate';
import { tipoIds } from '../tipoIds';
import { tipoSociedad } from '../tipoSociedad';
import './FormPrevinculacion.scss';
import { SelectComponet } from './SelectComponent/SelectComponet';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const rules = {
  montos: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
  ],
  montoSolicitud: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => numberValidate.min(value, 1000),
    },
    {
      validator: (_, value) => numberValidate.max(value, 10000000000),
    },
  ],
  cdTipoId: [{ required: true, message: requiredValueMessage() }],
  nombres: [
    { required: true, message: requiredValueMessage() },
    {
      validator: (_, value) => length(value, 2, 100),
    },
  ],
  telefono: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 7, 15),
    },
  ],
  ventasAño: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 300),
    },
  ],
  volumenFactura: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 1, 300),
    },
  ],
  numeroDocumento: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => length(value, 5, 20),
    },
  ],
  nit: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => totalLength(value, 9),
    },
  ],
  email: [
    { required: true, message: requiredValueMessage() },
    { max: 100, message: maxValueMessage(100) },
    { pattern: emailPattern, message: formatValueMessage() },
  ],
  password: [{ required: true, message: requiredValueMessage() }],
  checkboxes: [{ required: true, message: requiredValueMessage() }],
  scGrupoAsociado: [{ required: true, message: requiredValueMessage() }],
  cdActividad: [{ required: true, message: requiredValueMessage() }],
  sociedadComCiv: [{ required: true, message: requiredValueMessage() }],
  feConst: [{ required: true, message: requiredValueMessage() }],
  captcha: [{ required: true, message: requiredValueMessage() }],
};

export const FormPreVinculacion = ({
  handleSubmit,
  tipoForm,
  pagadorSelect,
}) => {
  const [form] = Form.useForm();
  const [producto, setProducto] = useState('');
  const [pagadoresSelectFiltered, setPagadoresSelectFiltered] = useState([]);
  const [actividadSelect, setActividadSelect] = useState({});

  const handleTipoProducto = (value) => {
    if (value === 'F') {
      setProducto('F');
      filterPagadoresFactoring();
    } else if (value === 'C') {
      setProducto('C');
      filterPagadoresConfirming();
    }
  };

  const filterPagadoresConfirming = () => {
    let pagadoresConfirming = [];
    for (let i = 0; i < pagadorSelect.length; i++) {
      let arrayTypes = pagadorSelect[i].type?.split('¬');
      if (arrayTypes !== undefined && arrayTypes !== null) {
        arrayTypes.map((tipo) => {
          if (tipo === 'C') {
            pagadoresConfirming.push(pagadorSelect[i]);
          }
        });
      }
    }
    setPagadoresSelectFiltered(pagadoresConfirming);
  };

  const filterPagadoresFactoring = () => {
    let pagadoresFactoring = [];
    for (let i = 0; i < pagadorSelect.length; i++) {
      let arrayTypes = pagadorSelect[i].type?.split('¬');
      if (arrayTypes !== undefined && arrayTypes !== null) {
        arrayTypes.map((tipo) => {
          if (tipo === 'F') {
            pagadoresFactoring.push(pagadorSelect[i]);
          }
        });
      }
    }
    setPagadoresSelectFiltered(pagadoresFactoring);
  };

  const OnActividadSearch = async (nombre) => {
    if (nombre === '' || nombre.length >= 3) {
      setActividadSelect({
        options: [],
        fetching: true,
      });

      try {
        const response = await consultaTiposActividades({
          descripcion: nombre,
        });

        setActividadSelect({
          ...actividadSelect,
          options: response.data,
          fetching: false,
        });
      } catch {
        notification.error({
          message: 'Error',
          description: 'Error Consultando Actividades',
          duration: 5,
        });

        setActividadSelect({
          ...actividadSelect,
          options: [],
          fetching: false,
        });
      }
    }
  };

  useEffect(() => {
    setProducto('');
    form.resetFields();
    OnActividadSearch('');
    if (tipoForm === 'N') {
      filterPagadoresConfirming();
    }
  }, [tipoForm]);

  return (
    <div className='header-container'>
      <h1>
        {tipoForm === 'N'
          ? 'Persona Natural'
          : tipoForm === 'J'
          ? 'Persona Jurídica'
          : null}
      </h1>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
      >
        {tipoForm == 'J' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='nit' label='NIT' rules={rules['nit'] || ''}>
                <Input placeholder='NIT' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='razonSocial'
                label='Nombre Empresa'
                rules={rules['nombres'] || ''}
              >
                <Input placeholder='Nombre Empresa' />
              </Form.Item>
            </Col>
          </Row>
        )}
        <h3>Datos de contacto</h3>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='prNombre'
              label='Nombres'
              rules={rules['nombres'] || ''}
            >
              <Input placeholder='Nombres' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='prApellido'
              label='Apellidos'
              rules={rules['nombres'] || ''}
            >
              <Input placeholder='Apellidos' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='cdTipoIdLeg'
              label='Tipo Documento'
              rules={rules['cdTipoId'] || ''}
            >
              <Select
                allowClear
                placeholder='Seleccionar'
                defaultActiveFirstOption={false}
              >
                {tipoIds.map((tipoDoc) => (
                  <Select.Option key={tipoDoc.key} value={tipoDoc.key}>
                    {tipoDoc.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='id'
              label='Número Documento'
              rules={rules['numeroDocumento'] || ''}
            >
              <Input placeholder='Número Documento' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='email'
              label='Correo Electrónico'
              rules={rules['email'] || ''}
            >
              <Input placeholder='Email' type='email' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='tel'
              label='Teléfono'
              rules={rules['telefono'] || ''}
            >
              <Input placeholder='Teléfono' />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col flex={'auto'}>
            <Form.Item
              name='volumenFactura'
              label='¿Cuál es el valor promedio de tu facturación mensual?'
              rules={rules['montos'] || ''}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={(value) => currencyInputFormat(value)}
                parser={(value) => currencyInputParser(value)}
                stringMode={true}
                placeholder='Volumen promedio de facturación mensual'
              />
            </Form.Item>
          </Col>
          <Col flex={'auto'}>
            <Form.Item
              name='ventasAnuales'
              label='¿Cuál fue el valor de tus ventas en el último año?'
              rules={rules['montos'] || ''}
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={(value) => currencyInputFormat(value)}
                parser={(value) => currencyInputParser(value)}
                stringMode={true}
                placeholder='Valor de ventas año actual'
              />
            </Form.Item>
          </Col>
        </Row>

        {tipoForm === 'J' ? (
          <>
            <Row gutter={16} justify='center'>
              <Col flex={'auto'}>
                <Form.Item
                  label='Seleccione el tipo de producto a solicitar'
                  name='tipoProducto'
                  rules={rules['cdTipoId'] || ''}
                >
                  <Select
                    placeholder='Seleccione Tipo Producto'
                    allowClear
                    defaultActiveFirstOption={false}
                    onChange={(value) => handleTipoProducto(value)}
                  >
                    <Select.Option value={'C'}>Confirming</Select.Option>
                    <Select.Option value={'F'}>Factoring</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {tipoForm === 'J' && producto === 'F' ? (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='cdActividad'
                      label='Tipo de actividad (CIIU)'
                      rules={rules['cdActividad'] || ''}
                    >
                      <Select
                        placeholder={SELECT_PLACE_HOLDER}
                        className='module-selector-select'
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={OnActividadSearch}
                        notFoundContent={
                          actividadSelect.fetching ? (
                            <Spin size='small' />
                          ) : null
                        }
                      >
                        {actividadSelect.options?.map((ciudad, i) => (
                          <Select.Option key={i} value={ciudad.codigo}>
                            {ciudad.descripcion}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='cdSociedadComercial'
                      label='Sociedad comercial o civil'
                      rules={rules['sociedadComCiv'] || ''}
                    >
                      <Select
                        allowClear
                        placeholder='Seleccionar'
                        defaultActiveFirstOption={false}
                      >
                        {tipoSociedad.map((item) => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name='feConst'
                      label='Fecha de Constitucion'
                      rules={rules['feConst'] || ''}
                    >
                      <DatePicker
                        className='radius'
                        allowClear={false}
                        style={{ width: '100%' }}
                        format={DATE_FORMAT}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='montoSolicitar'
                      label='¿Cuál es el monto a solicitar?'
                      rules={rules['montoSolicitud'] || ''}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        min={0}
                        formatter={(value) => currencyInputFormat(value)}
                        parser={(value) => currencyInputParser(value)}
                        stringMode={true}
                        placeholder='Monto a solicitar'
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <SelectComponet pagadorSelect={pagadoresSelectFiltered} />
              </>
            ) : tipoForm === 'J' && producto === 'C' ? (
              <>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name='cdActividad'
                      label='Tipo de actividad (CIIU)'
                      rules={rules['cdActividad'] || ''}
                    >
                      <Select
                        placeholder={SELECT_PLACE_HOLDER}
                        className='module-selector-select'
                        showSearch
                        allowClear
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={OnActividadSearch}
                        notFoundContent={
                          actividadSelect.fetching ? (
                            <Spin size='small' />
                          ) : null
                        }
                      >
                        {actividadSelect.options?.map((ciudad, i) => (
                          <Select.Option key={i} value={ciudad.codigo}>
                            {ciudad.descripcion}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name='cdSociedadComercial'
                      label='Sociedad comercial o civil'
                      rules={rules['sociedadComCiv'] || ''}
                    >
                      <Select
                        allowClear
                        placeholder='Seleccionar'
                        defaultActiveFirstOption={false}
                      >
                        {tipoSociedad.map((item) => (
                          <Select.Option key={item.key} value={item.key}>
                            {item.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name='feConst'
                      label='Fecha de Constitucion'
                      rules={rules['feConst'] || ''}
                    >
                      <DatePicker
                        className='radius'
                        allowClear={false}
                        style={{ width: '100%' }}
                        format={DATE_FORMAT}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <SelectComponet pagadorSelect={pagadoresSelectFiltered} />
              </>
            ) : null}
          </>
        ) : (
          <SelectComponet pagadorSelect={pagadoresSelectFiltered} />
        )}

        <div className='checkboxes' style={{}}>
          <Col span={20}>
            <Row gutter={16}>
              <div className='check'>
                <Form.Item
                  name='cdCentralesRiesgo'
                  valuePropName='checked'
                  rules={rules['checkboxes'] || ''}
                >
                  <Checkbox className='tax-info-check'>
                    Autorizo
                    <a
                      href='https://resources-affirmatum.s3.amazonaws.com/CentralesRiesgo.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {' '}
                      Consulta y reporte en centrales de riesgo
                    </a>
                  </Checkbox>
                </Form.Item>
              </div>
            </Row>

            <Row gutter={16}>
              <div className='check'>
                <Form.Item
                  name='cdDatosPersonales'
                  valuePropName='checked'
                  rules={rules['checkboxes'] || ''}
                >
                  <Checkbox className='tax-info-check'>
                    Autorizo
                    <a
                      href='https://resources-affirmatum.s3.amazonaws.com/tratamiento_de_datos.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {' '}
                      Tratamiento de datos personales
                    </a>
                  </Checkbox>
                </Form.Item>
              </div>
            </Row>

            <Row gutter={16}>
              <div className='check'>
                <Form.Item
                  name='cdTermYcond'
                  valuePropName='checked'
                  rules={rules['checkboxes'] || ''}
                >
                  <Checkbox className='tax-info-check'>
                    Acepto los
                    <a
                      href='https://www.exponencialconfirming.com/condiciones-de-uso-del-sistema-affirmatum/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {' '}
                      Términos y condiciones
                    </a>
                  </Checkbox>
                </Form.Item>
              </div>
            </Row>
          </Col>
        </div>

        <Row className='recaptcha'>
          <Col span={24}>
            <Form.Item name='captcha' rules={rules['captcha'] || ''}>
              <ReCAPTCHA sitekey={RESETPASS_RECAPTCHA_SITE} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify='center'>
          <Col span={8}>
            <Form.Item name='submit'>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
