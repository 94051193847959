import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_PROVEEDOR;

export const validarProveedorSolicitudes = (authToken, scProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/validar/${scProveedor}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const buscarSolicitudes = (authToken, scProveedor, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/${scProveedor}?${queryString.stringify(
        filtros
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const buscarPagadores = (authToken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/listar-pagadores`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};

export const guardarSolicitud = (authToken, scProveedor, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/guardarSolicitud/${scProveedor}`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const actualizarSolicitud = (authToken, scCompania, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/fichaNatural/actualizarFichaNatural/${scCompania}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const actualizarEstadoSolicitud = (authToken, scSolicitud, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/actualizarEstado/${scSolicitud}`,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      data: data,
    })
  );
};

export const grabarDocumentosSolicitud = (authtoken, formData, scSolicitud) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/cargar-archivos/${scSolicitud}`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${authtoken}`,
      },
      data: formData,
    })
  );
};

export const listarDocumentosAnexos = (authToken, scSolicitud) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/solicitudes/listar-archivos/${scSolicitud}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    })
  );
};
