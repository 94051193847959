import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { BUSQUEDA_TITLE, DATE_FORMAT } from '../../../../../utilities/messages';

const tipoPerson = [
  { key: 'J', description: 'Jurídica' },
  { key: 'N', description: 'Natural' },
];

const tipoProduct = [
  { key: 'F', description: 'Factoring' },
  { key: 'C', description: 'Confirming' },
];

const EstadosVinculacion = [
  { key: 'AP', description: 'Aprobado', type: 'F' },
  { key: 'E', description: 'En estudio', type: 'FC' },
  { key: 'RE', description: 'Rechazado', type: 'F' },
  { key: 'CE', description: 'Registro completo y enviado', type: 'FC' },
  { key: 'P', description: 'Prospecto', type: 'FC' },
  { key: 'A', description: 'Activado', type: 'FC' },
  { key: 'DI', description: 'Documentación incompleta', type: 'FC' },
];

const initialdata = {
  tipoFicha: 'J',
  tipoProducto: 'C',
  estadoVinGeneral: 'P',
  fechaRango: [moment(), moment()],
  nombre: '',
  idNit: '',
};

const VinculacionFilterForm = ({ handleSubmit, initialFormData }) => {
  const [form] = Form.useForm();
  const [tipoFicha, setTipoFicha] = useState('J');
  const [tipoProducto, setTipoProducto] = useState('C');
  const [estadosVin, setEstadoVin] = useState([]);

  const handleTipoficha = (value) => {
    if (value === 'N') {
      filtroEstadosConfirming();
    } else if (value === 'J' && tipoProducto === 'F') {
      filtroEstadosFactoring();
    }
    setTipoFicha(value);
  };

  const handleTipoProducto = (value) => {
    if (value === 'C') {
      filtroEstadosConfirming();
    } else if (value === 'F') {
      filtroEstadosFactoring();
    }
    setTipoProducto(value);
  };

  const filtroEstadosConfirming = () => {
    const estadosConfirming = EstadosVinculacion.filter(
      (estado) => estado.type === 'FC'
    );
    setEstadoVin(estadosConfirming);
  };

  const filtroEstadosFactoring = () => {
    const estadosFactoring = EstadosVinculacion.filter(
      (estado) => estado.type === 'FC' || estado.type === 'F'
    );
    setEstadoVin(estadosFactoring);
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.size > 0) {
      let urldata = {
        tipoFicha: queryParameters.get('tipoFicha'),
        fechaRango: [
          moment(queryParameters.get('feDesde'), 'YYYYMMDD'),
          moment(queryParameters.get('feHasta'), 'YYYYMMDD'),
        ],
        idNit: queryParameters.get('idNit'),
        nombre: queryParameters.get('nombre'),
        tipoProducto: queryParameters.get('tipoProducto'),
        estadoVinGeneral: queryParameters.get('estadoVinGeneral'),
      };
      if (urldata.tipoProducto === 'F') {
        filtroEstadosFactoring();
      } else if (urldata.tipoFicha === 'N' || urldata.tipoFicha === 'J') {
        filtroEstadosConfirming();
      }
      setTipoFicha(urldata.tipoFicha);
      form.setFieldsValue(urldata);
    } else {
      filtroEstadosConfirming();
      form.setFieldsValue(initialdata);
    }
  }, []);

  return (
    <div className='header-container'>
      <p className='title'>{BUSQUEDA_TITLE}</p>
      <Form
        name='content_form'
        layout='vertical'
        className='filters-container'
        form={form}
        onFinish={handleSubmit}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='tipoFicha' label='Tipo Ficha'>
              <Select onChange={(value) => handleTipoficha(value)}>
                {tipoPerson.map(({ key, description }) => (
                  <Select.Option key={key} value={key}>
                    {description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {tipoFicha === 'J' ? (
            <Col span={12}>
              <Form.Item name='tipoProducto' label='Tipo Producto'>
                <Select onChange={(value) => handleTipoProducto(value)}>
                  {tipoProduct.map(({ key, description }) => (
                    <Select.Option key={key} value={key}>
                      {description}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
          <Col span={24}>
            <Form.Item
              name='fechaRango'
              label='Rango De Fechas'
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione un rango de fechas',
                },
              ]}
            >
              <DatePicker.RangePicker name='fechaRango' format={DATE_FORMAT} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='idNit' label='Nit/Identificacion'>
              <Input type='number' />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='nombre' label='Nombre Proveedor'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name='estadoVinGeneral' label='Estado Vinculacion'>
              <Select>
                {estadosVin.map(({ key, description }) => (
                  <Select.Option key={key} value={key}>
                    {description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 32]}></Row>

        <Row justify='left'>
          <Col span={8}>
            <Form.Item>
              <Button
                htmlType='submit'
                className='form-btn btn-radius'
                type='primary'
              >
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default VinculacionFilterForm;
